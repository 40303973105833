import React from "react";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import KICard from "./innerComps/KICard";

type kompetenz = {
    title: string;
    text: any;
}

class KompInnerWrapper extends React.Component<any>{
    renderKompsJson(){
        let kompArr: any[] = [(<></>)];
        {Array.from(i18next.t('Kompetenzen', {returnObjects: true})).forEach((row, index) => {
            kompArr.push(
                <div className="col-sm d-flex" key={index.toString()}>
                    <KICard class={"KCard"} index={index.toString()} title={(row as kompetenz).title} text={(row as kompetenz).text}/>
                </div>);
        })}
        return kompArr;
    }
    render() {
        let arr: any = this.renderKompsJson();
        return (<>{arr}</>)
    }
}

export default withTranslation()(KompInnerWrapper)
