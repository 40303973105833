import React from 'react';
import './BigPicture.scss';

function BigPic() {

    let picture = <div className="BigPicSettings"><img src={window.location.origin + '/assets/imgs/reg7-480.jpg'} alt="image"/></div>;


    return (<>
        {picture}
    </>)

}


export default BigPic()
