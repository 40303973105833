import React from 'react';
import './interessen.scss';
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import IntInnerWrapper from "./innerWrapper/IntInnerWrapper";


function Interessen() {

    const {t} = useTranslation();


    return(<>
        <div id={"InteressenBG"}>
            <h2>{t('IntTitle')}</h2>
            <div className={"FDiv"}>
                <h3>{t('IntSub1')}</h3>
                <div className="col-sm d-flex">
                    <Card className="FCard" style={{display: 'inline-block'}}>
                        <Card.Body>
                            <Card.Title>{t('sprache')}</Card.Title>
                            <Card.Text className="cardText">
                                {t('level')}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className={"ZParent"}>
                <h3>{t('IntSub2')}</h3>
                <div className={"ZDiv"}>
                    <IntInnerWrapper key={"Zertifikate"} name={"Zert"}/>
                </div>
            </div>
            <div className={"IDiv"}>
                <h3>{t('IntSub3')}</h3>
                    <Card className="ICard">
                        <Card.Body>
                            <div className={"iCardyGrid"}>
                                <IntInnerWrapper key={"Interessen"} name={"Ints"}/>
                            </div>

                        </Card.Body>
                    </Card>
            </div>
        </div>
    </>)

}


export default Interessen
