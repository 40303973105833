import React from 'react';
import './komp.scss';
import KompInnerWrapper from "./innerWrapper/KompInnerWrapper";
import {useTranslation} from "react-i18next";


function Komp(props: any){

    const {t} = useTranslation();
    return (
        <>
            <div id='kompe' className="kompetenzenBG">
                <div id="Title">
                    <h2>{t('KompTitle')}</h2>
                </div>
                <div className="listing">
                    <h5>{t('KompSubTitle')}</h5>
                    <div className="cardBox">
                        <KompInnerWrapper/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Komp
