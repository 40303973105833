import React from 'react';
import './kontakt.scss';
import {withTranslation} from "react-i18next";
import {FaEnvelope, FaPhone} from "react-icons/fa";
import {Col, Container, Row} from 'react-bootstrap';
import KontaktRectangle from "./KontaktLong/KontaktRectangle";

class Kontakt extends React.Component<any, any> {




    render() {
        return (
            <div id={"kontaktBG"}>
                <h2>{this.props.t('contact now')}</h2>
                <div id={"formular"}>
                    <Container>
                        <Row>
                            <a href={"tel:+491726521129"}>
                                <Col>
                                    <KontaktRectangle className={"rec"} key={"1"} icon={[<FaPhone className={"icon"}/>]} title={this.props.t('PhoneTit')} text={this.props.t('PhoneTxt')} mobileText={this.props.t('MobilePhoneTxt')}/>
                                </Col>
                            </a>

                        </Row>
                        <Row>
                            <a href={"mailto:hubert.jansen@gmx.net"}>
                                <Col>
                                    <KontaktRectangle  key={"2"} icon={[<FaEnvelope className={"icon"}/>]} title={this.props.t('MailTit')} text={this.props.t('MailTxt')} mobileText={this.props.t('MobileMailTxt')}/>
                                </Col>
                            </a>
                        </Row>

                    </Container>

                </div>
            </div>
            )
    }
}

export default withTranslation()(Kontakt);
