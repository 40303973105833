import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {withTranslation} from "react-i18next";
import '../../cv.scss';

type aufgabe = {
    title?: string;
    date?: string;
    text?: string;
    quote?: string;
    erfolge?: any[];
}

class NormalJob extends React.Component<any>  {
    aufgaben() {
        const aufgaben: any[] = [(<></>)];
        //console.log(typeof ().props.aufgaben);

        if (this.props.aufgaben) {
            {
                (this.props.aufgaben).forEach((row, index1) => {
                    let text = (<></>);
                    let erfolge = (<></>);
                    if ((row as aufgabe).text) {
                        text = (<Typography className={"BodyText"}>
                            {(row as aufgabe).text}
                        </Typography>);
                    } else if((row as aufgabe).erfolge){

                        let erfolgLi = row.erfolge.map((erfolg, index2) => {
                            if(erfolg) {
                                return (
                                    <li key={index2 + "eP" + index1}>{(erfolg as string)}</li>)
                            } else {
                                return (<></>)
                            }
                        })
                        erfolge = (<div className={"ErfolgeList"}><ul>{erfolgLi}</ul></div>);
                    }
                    aufgaben.push(
                        <Accordion className={"SmallAccoItem"} key={index1 + "MiniAccoEP" + this.props.jobIndex}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls={index1 + "acco" + this.props.jobIndex}
                                id={index1 + "acco" + this.props.jobIndex}
                                key={index1 + "minAccoEP" + this.props.jobIndex}
                                className={"AccoTop"}>
                                <Typography className={"SmallAccoTitle"}>{(row as aufgabe).title}</Typography>
                                <Typography className={"SmallAccoDate"}>{(row as aufgabe).date}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {text}
                                {erfolge}
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        }
        return aufgaben;





    }

    render(){
        let text = (<></>);
        let quote = (<></>);
        if(this.props.text) text= this.props.text;
        if(this.props.quote) quote= (<span className={"infoText"}>{this.props.quote}</span>);

                return (<><Accordion className={"AccoItem"} key={this.props.jobIndex + "acco"}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls={"panel" + this.props.jobIndex + "a-content"}
                id={"panel" + this.props.jobIndex + " a-header"}
                className={"AccoTop"}>
                <Typography className={"AccoTitle"}>{this.props.title}</Typography>
                <Typography className={"AccoTown"}>{this.props.town}</Typography>
                <Typography className={"AccoDate"}>{this.props.date}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {quote}
                    {text}
                    {this.aufgaben()}
                </Typography>
            </AccordionDetails>
        </Accordion></>);
    }
}

export default withTranslation()(NormalJob);


