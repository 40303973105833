import React from 'react';
import './cv.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";
import CvInnerWrapper from "./innerWrapper/CvInnerWrapper";


function Cv() {

    const [t] = useTranslation();

    return (<>
        <div id="cv" className="cv">
            <h2>{t('Berufserfahrung')}</h2>
                <div className="AccoDiv">
                    <CvInnerWrapper id={"Jobs"}/>
                </div>

                <div className={"AccoDiv2BG"}>
                    <h2 id={"AusHeading"}>{t('AusbildungTitle')}</h2>
                    <h3 id={"Jahr1985"}>1985 - 1987</h3>
                    <div className={"AccoDiv2"}>
                        <div>
                            <CvInnerWrapper id={"ausbildung"}/>
                        </div>
                    </div>
                </div>

                <div className={"AccoDiv3BG"}>
                    <h2 id={"StuHeading"}>{t('Studium')}</h2>
                    <h3 id={"Jahr1980"}>1980 - 1984</h3>
                    <div className={"AccoDiv3"}>
                        <div>
                            <Accordion className={"AccoItem"} key={"universityAcco"}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls={"panel0a-contentAusbildung1980"}
                                    id={"0AusbildungAcco"}
                                    className={"AccoTop"}>
                                    <Typography className={"AccoTitle"}>Politologie & Germanistik</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        Johann-Wolfgang-Goethe Universität, Frankfurt
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>


        </div>
    </>)
}

export default Cv
