import React from 'react';
import './App.scss';
import BigPicture from "./Comps/BigPicture";
import AboutMe from './Comps/AboutMe';
import Komp from './Comps/Komp';
import Cv from "./Comps/Cv";
import $ from 'jquery';
import NavBar from "./Comps/Layout/NavBar";
import Kontakt from "./Comps/Kontakt";
import Interessen from "./Comps/interessen";

const App = () => {

    let actualHeight: number | undefined = window.innerHeight;
    if(!actualHeight){
        actualHeight = 100;
    }

    function jumpClick(elName: string) {
        if (elName !== undefined) {
            // @ts-ignore
            $([document.documentElement, document.body]).animate({scrollTop: ($(elName).offset().top)
                    - (document.body.offsetHeight / 140)
            }, 60);
        }
    }



    document.addEventListener("resize", ()=> {
        console.log($(window).innerHeight() + "APp")
        actualHeight =  $(window).innerHeight();
    });


    return (

        <div className="App">
            <NavBar className={"NavBar"} jumpClick={jumpClick}/>
            {BigPicture}
            <AboutMe jumpClick={jumpClick}/>
            <Komp aHeight={actualHeight.toString()}/>
            <Cv/>
            <Interessen/>
            <Kontakt/>
        </div>
    );

};

export default App;
