import React from 'react';
import '../cv.scss';
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NormalJob from "./innerComps/NormalJob";


type job = {
    stadt?: string;
    title: string;
    text?: string;
    date?: string;
    aufgaben?: any[];
    quote?: string;
}
type ausb = {
    was: string;
    info?: string;
    wo: string;
}


class CvInnerWrapper extends React.Component<any>  {
    render(){
        if(this.props.id === "ausbildung") {
            return (<>
                {Array.from(i18next.t('Ausbildung', {returnObjects: true})).map((row, index) => {
                    return (
                        <Accordion className={"AccoItem"} key={index.toString() + "ausbAcco"}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                key={index.toString + "ausbAccoSum"}
                                aria-controls={"panel" + index.toString() + "a-contentAusbildung"}
                                id={index.toString() + "AusbildungAcco"}
                                className={"AccoTop"}>
                                <Typography className={"AccoTitle"}>{(row as ausb).was}</Typography>
                                <Typography className={"AccoTown"}>{(row as ausb).info}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {(row as ausb).wo}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </>);
        }
        else if(this.props.id === "Jobs") {
            return (<>

                {Array.from(i18next.t('Jobs', {returnObjects: true})).map((row, jobIndex) => {
                   return (<NormalJob key={jobIndex} quote={(row as job).quote} index={jobIndex} aufgaben={(row as job).aufgaben} title={(row as job).title} text={(row as job).text} town={(row as job).stadt} date={(row as job).date}/>)

                })}
            </>);
        }
        else {
            return (<></>);
        }
    }

}

export default withTranslation()(CvInnerWrapper);
