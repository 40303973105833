import React from "react";
import i18next from "i18next";
import KICard from "./innerComps/KICard";
import Interesse from "./innerComps/Interesse";

type zert = {
    title: string;
    text: any;
}

class IntInnerWrapper extends React.Component<any>{

    render(){
        let IntArr: any[] = [(<></>)];
        if(this.props.name=="Zert"){
            {Array.from(i18next.t('Certs', {returnObjects: true})).forEach((row, index) => {
                IntArr.push(
                    <KICard class={"ZCard"} key={index} index={index} title={(row as zert).title} text={(row as zert).text}/>
                );
            })}
        }
        else if(this.props.name=="Ints"){
            {Array.from(i18next.t('Interessen', {returnObjects: true})).forEach((row, index) => {
                IntArr.push(
                    <Interesse key={index} index={index} text={(row as string)}/>
                );
            })}
        }
        return IntArr;
    }
}
export default IntInnerWrapper
