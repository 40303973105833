import React from 'react';
import './navBar.scss';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import ReactCountryFlag from "react-country-flag";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import {Container as MuiContainer, Slide, useScrollTrigger} from '@mui/material';

import MenuItem from '@mui/material/MenuItem';

function HideOnScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}



function NavBar(props) {
    const {t} = useTranslation();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    let langImg = (<></>);

    function changeLang(country: string){
        if(country === 'GB'){
            langImg = (<ReactCountryFlag countryCode={country} svg className={'lngIcon'}
                                         aria-label="English"/>);
        } else {
            langImg = (<ReactCountryFlag countryCode={country} svg className={'lngIcon'}
                                         aria-label="Deutsch"/>);
        }
    }

    if(i18n.language === 'en'){
        changeLang('GB');
    } else if (i18n.language === 'de'){
        changeLang('DE');

    }



    let width = $(window).innerWidth();
    if(!width){
        width = 1024;
    }

    const brandImgStyle = {
        height: 38,
        width: 38,
    }

    if (width >= 1024) {
        return (<>
            <Navbar fixed="top" variant="dark" className='topNav'>
                <Container>
                    <Navbar.Brand href="#home" className="NavBrand" onClick={() => props.jumpClick('#AbMe')}>
                        <span className={"LogoText"}>Hubert Jansen Management Consulting</span>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto navText">
                            <Nav.Link className="NavLink" onClick={() => props.jumpClick('#AbMe')}>{t('About me')}</Nav.Link>
                            <Nav.Link className="NavLink" onClick={() => props.jumpClick('#kompe')}>{t('Skills')}</Nav.Link>
                            <Nav.Link className="NavLink" onClick={() => props.jumpClick('#cv')}>{t('Work experience')}</Nav.Link>
                            <Nav.Link className="NavLink" onClick={() => props.jumpClick('#InteressenBG')}>{t('Proficiency')}</Nav.Link>
                            <Nav.Link className="NavLink" onClick={() => props.jumpClick('#kontaktBG')}>{t('Contact')}</Nav.Link>
                            <NavDropdown align={"end"} title={langImg} id="nav-dropdown" className={"lngChange text-right"}>
                                <NavDropdown.Item id={"dropDE"} className="lngChange-tab" eventKey="4.1" onClick={()=>{i18n.changeLanguage('de')}}>{t('German')}</NavDropdown.Item>
                                <NavDropdown.Item id={"dropUS"} eventKey="4.2" onClick={()=>{i18n.changeLanguage('en')}}>{t('English')}</NavDropdown.Item>

                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>)
    } else {
        return (
            <>
                <HideOnScroll {...props}>
                <AppBar className={"topNav"}>
                    <MuiContainer maxWidth="xl">
                        <Toolbar>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                            >
                                H. Jansen Management Consulting
                            </Typography>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    className={"MobileBtn"}
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    <MenuItem onClick={() => {props.jumpClick('#AbMe'); handleCloseNavMenu()}}>
                                    <Typography textAlign="center">{t('About me')}</Typography>
                                </MenuItem>
                                    <MenuItem onClick={() => {props.jumpClick('#kompe'); handleCloseNavMenu()}}>
                                        <Typography textAlign="center">{t('Skills')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => {props.jumpClick('#cv'); handleCloseNavMenu()}}>
                                        <Typography textAlign="center">{t('Work experience')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => {props.jumpClick('#InteressenBG'); handleCloseNavMenu()}}>
                                        <Typography textAlign="center">{t('Proficiency')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => {props.jumpClick('#kontaktBG'); handleCloseNavMenu()}}>
                                        <Typography textAlign="center" >{t('Contact')}</Typography>
                                    </MenuItem>

                                </Menu>
                            </Box>
                            <Typography
                                className={"mobileTxt"}
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                            >
                                HJ Management Consulting
                            </Typography>
                            <div>
                                <IconButton
                                    size="medium"
                                    aria-label="flag of current language"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    {langImg}
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >

                                    <MenuItem onClick={()=>{handleClose(); i18n.changeLanguage('de')}}>{t('German')}</MenuItem>
                                    <MenuItem onClick={()=>{handleClose(); i18n.changeLanguage('en')}}>{t('English')}</MenuItem>
                                </Menu>
                            </div>
                        </Toolbar>
                    </MuiContainer>
                </AppBar>
                </HideOnScroll>

    </>
        )
    }
}


export default NavBar
