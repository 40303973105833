import React from 'react';
import './AboutMe.scss';
import {Button} from "react-bootstrap";
import {withTranslation} from "react-i18next";


class aboutMe extends React.Component<any> {


 render() {
     return (
         <>
             <div id='AbMe' className="aboutMeBg">
                     <div className="text">
                         <div className={""}/>
                         <h1>Hubert Jansen</h1> <br/>
                         <h2>Management Consulting</h2>
                         <p><q><b>{this.props.t('aMQuote')}</b></q>{this.props.t('aMTxt')}</p>
                         <Button variant="warning"
                                 onClick={() => this.props.jumpClick('#kontaktBG')}>{this.props.t('aMContact')}</Button>{' '}
                     </div>
             </div>
         </>
     )
     }
}

export default withTranslation()(aboutMe);
