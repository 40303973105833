import React from "react";
import {Card} from "react-bootstrap";

class Interesse extends React.Component<any> {

    render(){
        return(<>
            <Card className="miniICard" style={{display: 'inline-block'}} key={(this.props.index as string) + "card"}>
                <Card.Body>
                    <Card.Text className="cardText">
                        {this.props.text}
                    </Card.Text>
                </Card.Body>
            </Card>
        </>)
    }

}

export default Interesse
