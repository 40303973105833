import React from 'react';
import './KontaktRectangle.scss';
import {Card, Col, Container, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";


class KRectangle extends React.Component<any>  {


    render(){
        return(
                <Card className="Rectang" key={(this.props.key as string)}>
                    <Card.Body>
                        <Card.Title>{this.props.title}</Card.Title>
                            <Container>
                                <Row>
                                    <Col xs lg={"1"}>
                                            {this.props.icon}
                                    </Col>
                                    <Col>
                                        <Card.Text className="KRcardText">
                                            <span className={"widthCheck"}>{this.props.text}</span>
                                            <span className={"widthCheckMobile"}>{this.props.mobileText}</span>
                                        </Card.Text>
                                    </Col>
                                </Row>
                            </Container>
                    </Card.Body>
                </Card>
            );
    }

}

export default withTranslation()(KRectangle);
