import React from "react";
import {Card} from "react-bootstrap";

class KICard extends React.Component<any> {

    render(){
        return(<><Card className={this.props.class} style={{display: 'inline-block'}} key={this.props.index + "card"}>
            <Card.Body>
                <Card.Title>{this.props.title}</Card.Title>
                <Card.Text className="cardText">
                    {this.props.text}
                </Card.Text>
            </Card.Body>
        </Card></>)
    }
}

export default KICard
