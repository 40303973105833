import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n/config';

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

